import './App.css';

import App from './App';
import Login from './Login'
import Forgot from './Forgot';
import {Routes,Route} from 'react-router-dom';
import { Redirect } from 'react-router';

function Routeee() {
  
  return (
    <Routes>
        <Route path="/" element={ <Login/> } />
        {/* <Route from="*" to path="/" /> */}
        {
          localStorage.getItem('CYBER_HEROES_admin') && <Route path="home" element={ <App/> } />
        }
        {
          !localStorage.getItem('CYBER_HEROES_admin') && <Route path="/" element={ <Login/> } />
        }
        {/* <Route path="*" element={ <Login/> } /> */}
        {/* <Route path="contact" element={ <Contact/> } /> */}
        <Route path="/forgot" element={ <Forgot/> } />
      </Routes>
  );
}

export default Routeee;
