// import logo from './logo.svg';
import './App.css';
import './ShowTemp'
// import { Button, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ShowTemp from './ShowTemp';
import Navbar from './navbar';
import { Card } from './card';
import { Button } from 'react-bootstrap';

function App() {

  
  const handleKeyPress = (e) => {
    if (e.key == 'Enter') {
      // console.log(e.target.value);
      // handleclick();
    }
  }
  
  return (
    <div id='background'>
      <Navbar></Navbar>
      <div className='container'>
        <div className="container text-center my-2">
          <h1 >Admin Controls</h1><span> <Link to='/' style={{ textDecoration: 'none' }}><Button style={{"display":'inline'}} onClick={()=>{localStorage.removeItem('CYBER_HEROES_admin',true)}} variant="danger" type="submit">
                        logout
                    </Button></Link></span>
                    <Link to='/forgot'><a className="nav-link">Change Password</a></Link>
          
          {/* <button className='btn btn-primary mx-2' onClick={handleclick}>Get temp</button> */}
        </div>

        <ShowTemp></ShowTemp>
        {/* <Card></Card> */}
        <br />
        <br /><br />
        <br />
        <br /><br />
        <br />
        <br /><br />

      </div>

    </div>
  );
}
export default App