import React from "react";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
function navbar() {
    return (
        <Navbar bg="dark" variant="dark">
            <Container>
                <Navbar.Brand>Cuber Hero Society Admin</Navbar.Brand>
               
                {/* <Navbar.Brand>Change Password</Navbar.Brand> */}
                
            </Container>
        </Navbar>
    )
}

export default navbar