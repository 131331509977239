import logo from './logo.svg';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import { Form, Button, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useState, useEffect, useCallback } from 'react';
import { get } from './Service';
import { useNavigate } from 'react-router-dom';
import Navbar from './navbar';

function Login() {
    const navigate = useNavigate();
    const handleOnClick = useCallback(() => navigate('/home', { replace: true }), [navigate]);
    const [uname, setuname] = useState();
    const [pass, setpass] = useState();
    const [username, setusername] = useState('');
    const [password, setpassword] = useState('');
    const [alert, setalert] = useState(false);

    const validate = () => {
        get().then((res) => { setpass(res.data.result.password); setuname(res.data.result.user) })
    }
    const check = () => {

    }
    useEffect(validate, [uname, pass])
    return (
        <><div>
            <Navbar></Navbar>

        </div><div className='App-header'>
                <Container>
                    <h1>CYBER HERO SOCIETY ADMIN LOGIN</h1>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>USERNAME</Form.Label>
                            <Form.Control type="text" placeholder="Enter username" onChange={(event) => {
                                setusername(event.target.value);
                            }} />
                            <Form.Text className="text-muted">
                                We'll never share your email with anyone else.
                            </Form.Text>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Password" onChange={(event) => {
                                setpassword(event.target.value);
                            }} />
                        </Form.Group>
                        {alert && <h5 style={{ 'color': 'red' }}>wrong password</h5>}
                        {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
        <Form.Check type="checkbox" label="Check me out" />
    </Form.Group> */}
                        {/* <Link to='/home' style={{ textDecoration: 'none' }}><a className="nav-link"><Button onClick={()=>{console.log(username,password)}} variant="danger" type="submit">
        Submit
    </Button></a></Link> */}
                        <Link to='/forgot'><a className="nav-link">Change Password</a></Link>
                        <a className="nav-link"><Button onClick={() => {
                            // console.log(username, password, uname, pass);
                            if ((username === uname) && (password === pass)) {
                                localStorage.setItem('CYBER_HEROES_admin', true);

                                handleOnClick();
                                // window.location.reload();

                            }
                            else {
                                setalert(true);
                            }
                        }} variant="danger">
                            Submit
                        </Button></a>

                    </Form>
                </Container>
            </div></>
    );
}

export default Login;
