import axios from "axios";
var url="https://herobackend.cyberherosociety.com/admin";

export async function update(pre,mint) {
    return new Promise(async (resolve, reject) => {
        var p,m;
        // if(pre === false){
        //     p=0;
        // }
        // else{
        //     p= 1;
        // }
        // if(mint === false){
        //     p=0;
        // }
        // else{
        //     p= 1;
        // }
        console.log(pre,mint)
        axios.post(url,{"pre":pre,"mint":mint}).then((res)=>{
          return resolve(res);
       }).catch((e)=>{return reject(e);})
   });
} 

export async function get() {
    return new Promise(async (resolve, reject) => {
        axios.put(url).then((res)=>{
          return resolve(res);
       }).catch((e)=>{return reject(e);})
   });
}

export async function pchange(password) {
    return new Promise(async (resolve, reject) => {
        axios.post(url+"pass",{'pass':password}).then((res)=>{
          return resolve(res);
       }).catch((e)=>{return reject(e);})
   });
}
