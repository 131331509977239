import logo from './logo.svg';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import { Form, Button, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useState, useEffect, useCallback } from 'react';
import { get, pchange } from './Service';
import { useNavigate } from 'react-router-dom';
import Navbar from './navbar';

function Forgot() {
    const navigate = useNavigate();
    const handleOnClick = useCallback(() => navigate('/', { replace: true }), [navigate]);
    // const [uname, setuname] = useState();
    // const [pass, setpass] = useState();
    const [password, setpassword] = useState('');

    const [currentpassword, setcurrentpassword] = useState('');
    const [newpassword, setnewpassword] = useState('');

    const [alert, setalert] = useState(false);
    const [success, setsuccess] = useState(false);

    const validate = () => {
        get().then((res) => {setpassword(res.data.result.password) })
    }
    const check = (newpassword) => {
        pchange(newpassword).then((res)=>{setsuccess(true)})
    }
    useEffect(validate);
    // useEffect(check);
    return (
        <><div>
            <Navbar></Navbar>

        </div><div className='App-header'>

                <Container>
                    <h1>Forgot Password</h1>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Change password For CYBER HERO ADMIN</Form.Label>
                            {/* <Form.Control type="text" placeholder="Enter current password" onChange={(event) => {
        setusername(event.target.value)
    }} /> */}
                            {/* <Form.Text className="text-muted">
        We'll never share your email with anyone else.
    </Form.Text> */}
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Current Password</Form.Label>
                            <Form.Control type="password" placeholder="Password" onChange={(event) => {
                                setcurrentpassword(event.target.value);
                            } } />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>New Password</Form.Label>
                            <Form.Control type="password" placeholder="Password" onChange={(event) => {
                                setnewpassword(event.target.value);
                            } } />
                        </Form.Group>

                        {alert && <h5 style={{ 'color': 'red' }}>wrong  password </h5>}
                        {success && <h5 style={{ 'color': 'green' }}>  password changed successfully</h5>}
                        {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
        <Form.Check type="checkbox" label="Check me out" />
    </Form.Group> */}
                        {/* <Link to='/home' style={{ textDecoration: 'none' }}><a className="nav-link"><Button onClick={()=>{console.log(username,password)}} variant="danger" type="submit">
        Submit
    </Button></a></Link> */}
                        <a className="nav-link"><Button onClick={() => {
                            // console.log( password,"1", currentpassword,"1",newpassword);
                            if ((currentpassword === password) && (newpassword)) {
                                console.log("bloack");
                                check(newpassword);
                                handleOnClick();

                            }
                            else {
                                setalert(true);
                            }
                        } } variant="danger">
                            Submit
                        </Button></a>

                    </Form>
                </Container>
            </div></>
    );
}

export default Forgot;
