import React from 'react';
import { Container, Row, Col, Card } from "react-bootstrap";
import ToggleButton from 'react-toggle-button'
import { update, get } from "./Service";
import axios from 'axios';
// import { keyframes,css,jsx } from "@emotion/react";
 class ShowTemp extends React.Component {
    
//     url="http://herobackend.cyberherosociety.com/admin";
//    up(pre,mint){
//     axios.post(this.url,{"pre":pre,"mint":mint}).then((res)=>{
//         console.log(res)
//      }).catch((e)=>{console.log(e);})
//    }
//    g(){
//     axios.put(this.url).then((res)=>{
//         console.log(res);
//        let p=res.data.result.isPresaleMint;
//        let m=res.data.result.isMintLive;
//        this.setState({presale:p,sale:m});
//       }).catch((e)=>{console.log(e);})
//    }
    constructor() {
        super();
        
        
        
        this.state={
            sale:'',
            presale:'',
            alert:false,
            update:false,

        }
        
        
        
      }
      componentDidMount(){
        get().then((res)=>{
            console.log(res.data.result);
            this.setState({presale:res.data.result.isPresaleMint,sale:res.data.result.isMintLive});
            this.setState({alert:false});
        //    p=res.data.result.isPresaleMint;
        //    m=res.data.result.isMintLive;
          })
          .catch((e)=>{console.log(e);this.setState({alert:true});})
        // console.log(this.state);
      }
      componentDidUpdate(){
        { this.state.update && 
        update(this.state.presale,this.state.sale).then((res)=>{console.log(res);this.setState({alert:false,update:false});}).catch((e)=>{console.log(e);this.setState({alert:true});});
            
        }          
      }
      alert(){
          
      }
      

    render() {
        return(
            <div className="container" >
            <Card style={{
                "backgroundImage": "url(https://images.unsplash.com/photo-1536514498073-50e69d39c6cf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871&q=80)", opacity: .7
                , 'borderRadius': 10
            }}>
                <Card.Body>
                    <Container>

                        <Row>
                            <Col><h3>Pre-Sale Mint : </h3><h6><ToggleButton
                                value={this.state.presale}
                                onToggle={(value) => {
                                    // console.log("tog",value)
                                    this.setState({
                                        presale: (!this.state.presale),
                                        update:true
                                    })
                                    // update(this.state.presale,this.state.sale).then((res)=>{console.log(res);if(res==='undefined'){this.setState({
                                    //     presale: (!this.state.presale)
                                    // })}}).catch((e)=>{console.log(e);});
                                }} /></h6></Col>
                            <Col><h3>Normal Mint : </h3><h6>
                                <ToggleButton
                                value={this.state.sale}
                                onToggle={(value) => {
                                    this.setState({
                                        sale: (!this.state.sale),
                                        update:true
                                    })
                                    // update(this.state.presale,this.state.sale).then((res)=>{console.log(res);if(res==='undefined'){ this.setState({
                                    //     sale: (!this.state.sale)
                                    // })}}).catch((e)=>{console.log(e);});
                                }} />
                                </h6></Col>

                        </Row>
                        <Row>
                            { this.state.alert && <><h1>Error Occurred</h1><h6>Values Not updated <br />Please refresh the page</h6></>

                            }
                        </Row>
                        <br />
                    </Container>
                </Card.Body>
            </Card>
            <div>
            </div>
        </div>
        )
    }
}

export default ShowTemp;